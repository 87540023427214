@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Inter:300,400,500,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700,800);
html {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #F2F2F2;
  font-family: 'Roboto', 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
a {
  text-decoration: none !important;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
/* [type=button]{
  -webkit-appearance: none !important;
} */

/* TODO: Migrate to Material-UI implementation */

.styles_react-code-input-container__1Rb6l {
  position: relative;
}

.styles_react-code-input__2oinV > input {
  border: solid 1px #B7B7B7;
  border-right: none;
  font-size: 20px;
  color: #525461;
  text-align: center;
  box-sizing: border-box;
  border-radius: 0;
  -webkit-appearance: initial;
}

.styles_react-code-input__2oinV > input:last-child {
  border-right: solid 1px #B7B7B7;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.styles_react-code-input__2oinV > input:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.styles_react-code-input__2oinV > input:focus {
  outline: none;
  border: 2px solid #20B2EB;
  caret-color: #20B2EB;
}

.styles_react-code-input__2oinV > input:focus + input {
  border-left: none;
}

.styles_loading__1pquk {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
}

.styles_blur__J_8_Y {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  opacity: 0.5;
  -webkit-filter: blur(0.5px);
          filter: blur(0.5px);
  transition: opacity 0.3s;
}

.styles_title__2QwMz {
  margin: 0;
  height: 20px;
  padding-bottom: 10px;
}

.styles_spin__Zx9PI {
  display: inline-block;
  -webkit-animation: styles_loadingCircle__11eJM 1s infinite linear;
          animation: styles_loadingCircle__11eJM 1s infinite linear;
}

@-webkit-keyframes styles_loadingCircle__11eJM {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes styles_loadingCircle__11eJM {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

