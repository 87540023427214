@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800');
@import url('https://fonts.googleapis.com/css?family=Inter:300,400,500,600,700,800');
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700,800');
html {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #F2F2F2;
  font-family: 'Roboto', 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
a {
  text-decoration: none !important;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
/* [type=button]{
  -webkit-appearance: none !important;
} */
